import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";

import { roundingNumbers } from "utils/roundingNumbers";
import useRetailers from "../../../../hooks/useRetailers";
import useCountry, { NUMBER_TYPE } from "../../../../hooks/useCountry";

import cancel2 from "assets/images/cancel2.svg";
import green from "assets/images/green.svg";
import red from "assets/images/red.svg";
import marketplace from "assets/images/marketplace.svg";
import promotions from "assets/images/promotions.svg";
import pricematch from "assets/images/pricematch.svg";
import pricelock from "assets/images/padlock.svg";

export const PopupPrice = (props) => {
  const {
    data,
    data: { averagePrices, basePrice, promotedPrice, shelfPrice },
    closePopup,
    onPromotion,
    isPriceMatch,
    priceMatchDesc,
    isMarketplace,
    marketplaceDesc,
    isPriceLock
  } = props;

  const { formatCurrencyNumber } = useCountry();
  const [pricings, setPricings] = useState([]);
  const [dates, setDates] = useState([]);
  const { getRemoteLogo } = useRetailers();

  useEffect(() => {
    if (averagePrices?.length) {
      const basePrices = averagePrices.map((item) => Number(item.basePrices));
      const shelfPrices = averagePrices.map((item) => Number(item.shelfPrices));

      const pricings = [
        {
          name: "Base Price",
          data: basePrices,
          color: "#25c891",
        },
        {
          name: "Shelf Price",
          data: shelfPrices,
          color: "#7d82a5",
        },
      ];

      const isPromoted = averagePrices.map((item) => item.hasOwnProperty("promotedPrices"));

      if (isPromoted.includes(true)) {
        const promotedPrices = averagePrices.map((item) => Number(item.promotedPrices));

        pricings.push({
          name: "Promoted Price",
          data: promotedPrices,
          color: "#ffc502",
        });
      }

      setPricings(pricings);

      const date = averagePrices.map((item) => moment(item.date).format("DD MMM YYYY"));
      setDates(date);
    }
  }, [data]);

  const options = {
    title: "",
    chart: {
      type: "areaspline",
      width: 760,
      height: 300,
      style: {
        fontFamily: "Gilroy-Medium",
      },
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
      areaspline: {
        fillColor: 0,
        marker: {
          enabled: false,
        },
        lineWidth: 3,
        threshold: null,
      },
    },
    xAxis: {
      crosshair: {
        width: 1,
        color: "gray",
        dashStyle: "solid",
      },
      type: "datetime",
      labels: {
        step: parseInt(dates.length / 3),
      },
      categories: dates,
    },
    yAxis: {
      title: {
        text: "",
      },
      minTickInterval: 0.1,
      labels: {
        formatter: function () {
          return `${formatCurrencyNumber(this.value, NUMBER_TYPE.CURRENCY)}`;
        },
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
      backgroundColor: null,
      borderWidth: 0,
      zIndex: 999,
      formatter: function () {
        let initialData = this.points.map((item, index, array) => {
          return `<div class='wrapper'>
                                <div class='box'>
                                    <div class='color' style='background: ${item.color}'></div>
                                    <div class='name'>${item.series.options.name}</div>
                                </div>
                                <div class='price'>${formatCurrencyNumber(
                                  roundingNumbers(item.y),
                                  NUMBER_TYPE.CURRENCY
                                )}</div>
                            </div>`;
        });
        initialData = initialData.join("");

        return `<div class='wrapper-category'>
                        <div class='wrapper-box'>
                        <div>
                            <div class='date'>${moment(this.points[0].key).format("DD MMM YY")}</div>
                            <div>
                                ${initialData}
                            </div>
                        </div>
                    </div>`;
      },
    },
    legend: {
      enabled: false,
    },
    series: pricings,
  };

  return (
    <div className="popup-wrapper">
      <div className="popup-box">
        <img className="cancel" src={cancel2} alt="cancel" onClick={closePopup} />
        <div className="title-box">
          <img className="product-img" src={data.image} alt="product" />
          <div style={{ width: 615 }}>
            <div className="title-product">{data.title}</div>
            <div className="date-product">Last Updated: {moment(data.lastUpdate).format("DD MMM YYYY")}</div>
            <div className="product-link" style={{ justifyContent: "space-between", alignItems: "flex-start" }}>
              <div style={{ display: "flex", justifyItems: "flex-start" }}>
                <img src={getRemoteLogo(data.retailer)} style={{ display: "block" }} alt="retailer" />
                <a href={data.href} target="_blank" style={{ display: "block" }}>
                  See on Retailer Website
                </a>
              </div>
              <div style={{ alignSelf: "end" }}>
                {onPromotion &&
                  <div style={{ display: "flex", justifyItems: "flex-start" }}>
                    <img src={promotions} alt="promotions" style={{ width: 25, marginRight: "10px" }} />
                    <div style={{ height: 21, margin: "2px 0", padding: "0px" }}>Promotion Observed</div>
                  </div>
                }
                {isPriceMatch &&
                  <div style={{ display: "flex", justifyItems: "flex-start" }}>
                    <img src={pricematch} alt="pricematch" style={{ width: 25, marginRight: "10px" }} />
                    <div style={{ height: 21, margin: "2px 0", padding: "0px" }}>{priceMatchDesc}</div>
                  </div>
                }
                {isPriceLock &&
                  <div style={{ display: "flex", justifyItems: "flex-start" }}>
                    <img src={pricelock} alt="priceLock" style={{ width: 25, marginRight: "10px" }} />
                    <div style={{ height: 21, margin: "2px 0", padding: "0px" }}>Price Lock Observed</div>
                  </div>
                }
                {isMarketplace &&
                  <div style={{ display: "flex", justifyItems: "flex-start" }}>
                    <img src={marketplace} alt="marketplace" style={{ width: 25, marginRight: "10px" }} />
                    <div style={{ height: 21, margin: "2px 0", padding: "0px" }}>{"Sold by " + marketplaceDesc}</div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="chart-box">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
        <div className="price-box">
          {basePrice && (
            <div>
              <div style={{ background: "#25c891" }} className="line"></div>
              <div style={{ background: "#d8f7ef" }} className="price-type">
                <div className="title-price">Base Price</div>
                <div className="pricing">
                  <div>
                    <div className="price-product">
                      {formatCurrencyNumber(roundingNumbers(basePrice.startPrice), NUMBER_TYPE.CURRENCY)}
                    </div>
                    <div className="date-prod">{moment(basePrice.startPriceDate).format("DD MMM YYYY")}</div>
                  </div>
                  <div>
                    <div className="price-product">
                      {formatCurrencyNumber(roundingNumbers(basePrice.endPrice), NUMBER_TYPE.CURRENCY)}
                    </div>
                    <div className="date-prod">{moment(basePrice.endPriceDate).format("DD MMM YYYY")}</div>
                  </div>
                </div>
                <div className="change">
                  {basePrice.percent ? <div>{Number.parseFloat(basePrice.percent).toFixed(1)}%</div> : "-"}
                  {Number(basePrice.percent) !== 0 ? (
                    <img className="product-direction" src={basePrice.direction ? green : red} alt="deriction" />
                  ) : null}
                </div>
                <div className="date-prod">Change</div>
              </div>
            </div>
          )}
          {shelfPrice && (
            <div>
              <div style={{ background: "#7d82a5" }} className="line"></div>
              <div style={{ background: "#fafafa" }} className="price-type">
                <div className="title-price">Shelf Price</div>
                <div className="pricing">
                  <div>
                    <div className="price-product">
                      {formatCurrencyNumber(roundingNumbers(shelfPrice.startPrice), NUMBER_TYPE.CURRENCY)}
                    </div>
                    <div className="date-prod">{moment(shelfPrice.startPriceDate).format("DD MMM YYYY")}</div>
                  </div>
                  <div>
                    <div className="price-product">
                      {formatCurrencyNumber(roundingNumbers(shelfPrice.endPrice), NUMBER_TYPE.CURRENCY)}
                    </div>
                    <div className="date-prod">{moment(shelfPrice.endPriceDate).format("DD MMM YYYY")}</div>
                  </div>
                </div>
                <div className="change">
                  {shelfPrice.percent ? <div>{Number.parseFloat(shelfPrice.percent).toFixed(1)}%</div> : "-"}
                  {Number(shelfPrice.percent) !== 0 ? (
                    <img className="product-direction" src={shelfPrice.direction ? green : red} alt="deriction" />
                  ) : null}
                </div>
                <div className="date-prod">Change</div>
              </div>
            </div>
          )}
          {promotedPrice && (
            <div>
              <div style={{ background: "#ffc502" }} className="line"></div>
              <div style={{ background: "#fff9e4" }} className="price-type">
                <div className="promoted-box">
                  <div className="title-price">Promoted Price</div>
                  {/* <a className="product-link" href={data.href} target="_blank">
                    Details
                  </a> */}
                </div>
                {Number(promotedPrice.percent) === 0 && !promotedPrice.averageDiscount ? (
                  <div className="no-promotions">No Promotions</div>
                ) : (
                  <>
                    <div className="pricing">
                      <div>
                        {promotedPrice.averageDiscount ? (
                          <div className="price-product">
                            {formatCurrencyNumber(roundingNumbers(promotedPrice.averageDiscount), NUMBER_TYPE.CURRENCY)}
                          </div>
                        ) : (
                          "-"
                        )}
                        <div className="date-prod">Average Discount</div>
                      </div>
                    </div>
                    <div className="change">
                      {promotedPrice.percent ? <div>{Number.parseFloat(promotedPrice.percent).toFixed(1)}%</div> : "-"}
                      {Number(promotedPrice.percent) !== 0 ? (
                        <img
                          className="product-direction"
                          src={promotedPrice.direction ? green : red}
                          alt="deriction"
                        />
                      ) : null}
                    </div>
                    <div className="date-prod">Average change</div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PopupPrice;
